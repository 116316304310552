import {Action} from '@ngrx/store';

export const LOGIN_START = '[Auth] Login Start';
export const AUTO_LOGIN = '[Auth] Auto Login';
export const AUTHENTICATE_SUCCESS = '[Auth] Login Success';
export const AUTHENTICATE_FAIL = '[Auth] Login Fail';
export const LOGOUT = '[Auth] Logout';

export class LoginStart implements Action {
  readonly type = LOGIN_START;
  constructor(
    public payload: {
      username: string;
      password: string;
    }
  ) {
  }
}

export class AutoLogin implements Action {
  readonly type = AUTO_LOGIN;
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;
  constructor(
    public payload: {
      token: string;
      user: string;
      workspace: string;
    }
  ) {
  }
}

export class AuthenticateFail implements Action {
  readonly type = AUTHENTICATE_FAIL;
  constructor(
    public payload: string
  ) {
  }
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export type AuthActions =
  LoginStart |
  AutoLogin |
  AuthenticateSuccess |
  AuthenticateFail |
  Logout;
